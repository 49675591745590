@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,400;0,700;1,400;1,700&family=Kanit:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-right: 8px;
  padding-left: 8px;
}

.container-fluid {
  padding-right: 16px;
  padding-left: 16px;
}

@media only screen and (min-width: 576px) {
  .container {
    width: 560px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    width: 752px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    width: 976px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1184px;
    max-width: 100%;
  }
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-offset-0,
.col-sm-offset-1,
.col-sm-offset-2,
.col-sm-offset-3,
.col-sm-offset-4,
.col-sm-offset-5,
.col-sm-offset-6,
.col-sm-offset-7,
.col-sm-offset-8,
.col-sm-offset-9,
.col-sm-offset-10,
.col-sm-offset-11,
.col-sm-offset-12,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-offset-0,
.col-md-offset-1,
.col-md-offset-2,
.col-md-offset-3,
.col-md-offset-4,
.col-md-offset-5,
.col-md-offset-6,
.col-md-offset-7,
.col-md-offset-8,
.col-md-offset-9,
.col-md-offset-10,
.col-md-offset-11,
.col-md-offset-12,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-offset-0,
.col-lg-offset-1,
.col-lg-offset-2,
.col-lg-offset-3,
.col-lg-offset-4,
.col-lg-offset-5,
.col-lg-offset-6,
.col-lg-offset-7,
.col-lg-offset-8,
.col-lg-offset-9,
.col-lg-offset-10,
.col-lg-offset-11,
.col-lg-offset-12,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-offset-0,
.col-xl-offset-1,
.col-xl-offset-2,
.col-xl-offset-3,
.col-xl-offset-4,
.col-xl-offset-5,
.col-xl-offset-6,
.col-xl-offset-7,
.col-xl-offset-8,
.col-xl-offset-9,
.col-xl-offset-10,
.col-xl-offset-11,
.col-xl-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-basis: 100%;
  padding-right: 8px;
  padding-left: 8px;
  max-width: 100%;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.start-xs {
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  justify-content: center;
  text-align: center;
}

.end-xs {
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

.initial-order-xs {
  order: initial;
}

@media only screen and (min-width: 576px) {
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-sm {
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }

  .initial-order-sm {
    order: initial;
  }
}

@media only screen and (min-width: 768px) {
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-md {
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  .end-md {
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }

  .initial-order-md {
    order: initial;
  }
}

@media only screen and (min-width: 992px) {
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }

  .initial-order-lg {
    order: initial;
  }
}

@media only screen and (min-width: 1200px) {
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-offset-0,
  .col-xl-offset-1,
  .col-xl-offset-2,
  .col-xl-offset-3,
  .col-xl-offset-4,
  .col-xl-offset-5,
  .col-xl-offset-6,
  .col-xl-offset-7,
  .col-xl-offset-8,
  .col-xl-offset-9,
  .col-xl-offset-10,
  .col-xl-offset-11,
  .col-xl-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xl-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-xl-offset-0 {
    margin-left: 0;
  }

  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xl-offset-3 {
    margin-left: 25%;
  }

  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xl-offset-6 {
    margin-left: 50%;
  }

  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xl-offset-9 {
    margin-left: 75%;
  }

  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-xl {
    justify-content: flex-start;
    text-align: start;
  }

  .center-xl {
    justify-content: center;
    text-align: center;
  }

  .end-xl {
    justify-content: flex-end;
    text-align: end;
  }

  .top-xl {
    align-items: flex-start;
  }

  .middle-xl {
    align-items: center;
  }

  .bottom-xl {
    align-items: flex-end;
  }

  .around-xl {
    justify-content: space-around;
  }

  .between-xl {
    justify-content: space-between;
  }

  .first-xl {
    order: -1;
  }

  .last-xl {
    order: 1;
  }

  .initial-order-xl {
    order: initial;
  }
}

@media only screen and (max-width: 575px) {
  .hidden-xs {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .hidden-xl {
    display: none;
  }
}
* {
  font-family: 'Chivo', sans-serif;
}

body {
  position: relative;
  font-size: 13px;
}

body::before {
  content: '';
  background: url(https://services.workrails.com/static/media/background.1c4e7e1c.svg) 50% repeat;
  background-attachment: fixed;
  background-size: cover;
  opacity: 0.3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.categories__menu {
  font-size: 14px;
}

[class*='hero'].container {
  text-align: center;
}

.navbar-container {
  position: fixed;
  z-index: 1000;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  overflow: visible;
}

.navbar-menu a {
  padding: 0 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 55px;
  display: inline-block;
}

.navbar-menu a:hover {
  opacity: 0.7;
}

.book-demo {
  border: 2px solid #375aaa;
  border-radius: 10px;
  margin-left: 20px;
  color: #375aaa;
}

.book-demo:hover {
  border: 2px solid #375aaa;
  background-color: #375aaa;
  color: #fff;
}

.navbar-menu a.book-demo:hover {
  opacity: 1;
}

.product-list-price p {
  font-size: 17px;
  color: #888;
  font-weight: 400;
  margin-top: 10px;
}

.product-name a {
  font-size: 17px;
  font-weight: 600;
  color: #555;
}

.product-description {
  color: #212529;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
}

.title {
  font-size: 33px;
  text-align: center;
  padding: 75px 0 25px;
}

.mobile-menu-list {
  margin-top: 88px;
}

.proposal-builder input,
.proposal-builder .react-date-picker > div {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-style: dotted;
  outline: none;
}

.proposal-builder input,
.proposal-builder input + div {
  text-align: center;
}

.proposal-builder input:focus {
  box-shadow: none;
  border-bottom-color: #375aaa;
}

.proposal-builder button.main-button {
  border-radius: 5px;
}

.proposal-builder button.main-button:focus {
  outline: none;
}

.dropdown-list {
  border-radius: 5px;
}

.dropdown-trigger button {
  border-radius: 5px;
}

.dropdown-trigger button:focus {
  outline: none;
}

.split-view-switcher li {
  font-family: 'Kanit', sans-serif;
}

.split-view-switcher li:last-child {
  border-radius: 0 5px 5px 0;
}

.split-view-switcher li:first-child {
  border-radius: 5px 0 0 5px;
}

@media only screen and (min-width: 1500px) {
  .container {
    width: 1484px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  [class*='hero_']:not(.container) {
    height: 400px;
  }

  [class*='hero'].container {
    padding: 30px 0;
  }
}

@media only screen and (min-width: 320px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu {
    cursor: pointer;
  }

  .mobile-menu,
  .mobile-menu-list {
    display: block;
  }

  .mobile-menu-list {
    position: fixed;
    z-index: 1001;
    background: #fff;
    width: 100%;
    padding: 20px 0;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }

  .mobile-menu-list a {
    display: block;
    font-weight: 500;
    font-size: 15px;
    width: 100%;
    padding: 20px 0;
  }

  .mobile-menu-list a:hover {
    opacity: 0.8;
  }

  .mobile-menu-list a.book-demo {
    margin-left: 0;
    border: none;
  }

  .mobile-menu-list a.book-demo:hover {
    border: none;
    background-color: transparent;
    color: #375aaa;
  }
}

@media only screen and (min-width: 992px) {
  .desktop-menu {
    display: block;
  }

  .mobile-menu,
  .mobile-menu-list {
    display: none;
  }
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-date-picker {
  display: inline-flex;
  position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  box-sizing: border-box;
}
.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px +  0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.tooltip-container {
  --tooltipBackground: #fff;
  --tooltipBorder: #c0c0c0;
  --tooltipColor: #000;

  background-color: var(--tooltipBackground);
  border-radius: 3px;
  border: 1px solid var(--tooltipBorder);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  color: var(--tooltipColor);
  display: flex;
  flex-direction: column;
  padding: 0.4rem;
  transition: opacity 0.3s;
  z-index: 9999;
}

.tooltip-container[data-popper-interactive='false'] {
  pointer-events: none;
}

.tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
  pointer-events: none;
}

.tooltip-arrow::before {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.tooltip-arrow::after {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow {
  left: 0;
  margin-top: -0.4rem;
  top: 0;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::before {
  border-color: transparent transparent var(--tooltipBorder) transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::after {
  border-color: transparent transparent var(--tooltipBackground) transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-bottom: -1rem;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::before {
  border-color: var(--tooltipBorder) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::after {
  border-color: var(--tooltipBackground) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow {
  left: 0;
  margin-left: -0.7rem;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::before {
  border-color: transparent var(--tooltipBorder) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::after {
  border-color: transparent var(--tooltipBackground) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow {
  margin-right: -0.7rem;
  right: 0;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::before {
  border-color: transparent transparent transparent var(--tooltipBorder);
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::after {
  border-color: transparent transparent transparent var(--tooltipBackground);
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}

